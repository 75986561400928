import api from "../../api";
/* eslint-disable */
export default {
    namespaced: true,
    state: {
        projects: [],
    },
    getters: {
        getProjects(state) {
            return state.projects;
        }
    },
    mutations: {
        SetProjects(state, data) {
            state.projects = [];
            state.projects = data;
        }
    },
    actions: {

        GetAllProjects({ commit }) {
            return new Promise((resolve, rej) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .get(
                        `Projects/all`, {
                            headers: {
                                Authorization: token ? `Bearer ${token}` : '', 
                            }
                        }
                    )
                    .then((res) => {
                        resolve(res);
                        
                        commit("SetProjects", res.data);
                    })
                    .catch((err) => resolve(err));
            });
        },
        AddNewProject({ commit },data) {
            return new Promise((resolve, rej) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .post(
                        `Projects`,data, {
                            headers: {
                                Authorization: token ? `Bearer ${token}` : '', 
                            }
                        }
                    )
                    .then((res) => {
                        resolve(res);
                       
                    })
                    .catch((err) => resolve(err));
            });
        },
        EditProject({ commit },data) {
            
            return new Promise((resolve, rej) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .put(
                        `Projects`,data, {
                            headers: {
                                Authorization: token ? `Bearer ${token}` : '', 
                            }
                        }
                    )
                    .then((res) => {
                        resolve(res);
                       
                    })
                    .catch((err) => resolve(err));
            });
        },
        DeleteProject({ commit },data) {
           
            return new Promise((resolve, rej) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .delete(
                        `Projects/${data}`, {
                            headers: {
                                Authorization: token ? `Bearer ${token}` : '', 
                            }
                        }
                    )
                    .then((res) => {
                        resolve(res);
                       
                    })
                    .catch((err) => resolve(err));
            });
        },
    }
};
