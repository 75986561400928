import api from "../../api";
/* eslint-disable */
export default {
    namespaced: true,
    state: {
        events: [],
    },
    getters: {
        getEvens(state) {
            return state.events;
        }
    },
    mutations: {
        SetEvents(state, data) {
            state.events = [];
            state.events = data;
        }
    },
    actions: {

        GetAllEvents({ commit }) {
            return new Promise((resolve, rej) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .get(
                        `Events/all`, {
                            headers: {
                                Authorization: token ? `Bearer ${token}` : '', 
                            }
                        }
                    )
                    .then((res) => {
                        resolve(res);
                        
                        commit("SetEvents", res.data);
                    })
                    .catch((err) => resolve(err));
            });
        },
        AddNewEvent({ commit },data) {
            return new Promise((resolve, rej) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .post(
                        `Events`,data, {
                            headers: {
                                Authorization: token ? `Bearer ${token}` : '', 
                            }
                        }
                    )
                    .then((res) => {
                        resolve(res);
                       
                    })
                    .catch((err) => resolve(err));
            });
        },
        EditEvent({ commit },data) {
            return new Promise((resolve, rej) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .put(
                        `Events`,data, {
                            headers: {
                                Authorization: token ? `Bearer ${token}` : '', 
                            }
                        }
                    )
                    .then((res) => {
                        resolve(res);
                       
                    })
                    .catch((err) => resolve(err));
            });
        },
        DeleteEvent({ commit },data) {
           
            return new Promise((resolve, rej) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .delete(
                        `Events/${data}`, {
                            headers: {
                                Authorization: token ? `Bearer ${token}` : '', 
                            }
                        }
                    )
                    .then((res) => {
                        resolve(res);
                       
                    })
                    .catch((err) => resolve(err));
            });
        },
    }
};
