<template>
  <div class="mainContainer">
    <router-view />
  </div>
</template>
<script setup></script>
<style>
.mainContainer {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  font-size: 1vw;
}

.p-datatable {
  font-size: 1vw;
}

.scrollable {
  cursor: auto;
}

::-webkit-scrollbar {
  width: 0.5vw;
  height: 1vh;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: #fafafa;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #a3a3a3;
  border-radius: 10px;
}

/* :global(.p-dialog .p-dialog-header){
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: space-between !important;
  align-content: center !important;
  background-color: black;
} */

input[type="file"] {
  /* Hide the default file input button */
  display: none;
}

.p-dialog .p-dialog-footer {
  display: flex !important;
  direction: rtl !important;
  justify-content: space-between !important;
}

.p-column-filter-overlay-menu
  .p-column-filter-constraint
  .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
  display: none;
}
.p-datatable .p-datatable-tbody > tr > td {
  font-size: 16px;
}
.p-datatable .p-datatable-thead > tr > th {
  font-size: 16px;
}
.p-dialog .p-dialog-content {
  height: 100%;
}
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}
</style>
