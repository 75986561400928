import {
  createRouter,
   createWebHashHistory,
  //createWebHistory,
} from "vue-router";
// import jwt_decode from 'jwt-decode';

const ifAuthenticated = (to, from, next) => {
  if (localStorage.getItem("AMHZJwtToken")) {
    next();
    return;
  }
  next("/");
};

const checkGroupPermission = (requiredGroupIds) => {
  return (to, from, next) => {
    const GID = localStorage.getItem("GID");
    if (GID) {
      const groupId = Number(GID);
      if (requiredGroupIds.includes(groupId)) {
        next();
      } else {
        next("/"); // Redirect to home or an error page
      }
    } else {
      next("/"); // Redirect to home or login page if token is missing
    }
  };
};

const routes = [
  {
    path: "/",
    name: "login",
    meta: { title: 'login' },

    component: () => import("../views/loginView.vue"),
  },
  {
    path: "/home",
    name: "home",
    meta: { title: 'home' },

    beforeEnter: ifAuthenticated,
    component: () => import("../views/HomeView.vue"),
    children: [
      {
        path: "/events",
        name: "events",
        meta: { title: 'events' },

        component: () => import("../views/eventsView.vue"),
        //beforeEnter: checkGroupPermission([15]), // Example group IDs with access
      },
      {
        path: "/projects",
        name: "projects",
        meta: { title: 'projects' },

        component: () => import("../views/ProjectsView.vue"),
        //beforeEnter: checkGroupPermission([15]), // Example group IDs with access
      },
      {
        path: "/projectTypes",
        name: "projectTypes",
        meta: { title: 'projects' },

        component: () => import("../views/ProjectTypesView.vue"),
        //beforeEnter: checkGroupPermission([15]), // Example group IDs with access
      },     {
        path: "/Companies",
        name: "Companies",
        meta: { title: 'Companies' },

        component: () => import("../views/CompaniesView.vue"),
        //beforeEnter: checkGroupPermission([15]), // Example group IDs with access
      },
      {
        path: "/changePassword", // Define route with parameter ':id'
        name: "changePassword",
        meta: { title: 'changePassword' },

        component: () => import("../views/changePasswordView.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Default Title';
  next();
});

export default router;