import axios from "axios";
import https from "https-browserify";
import { router } from "./router"; // تأكد من استيراد الـ router الخاص بك بشكل صحيح
import { serverBasePath } from "./store/consts";

export default () => {
  const agent = new https.Agent({  
    rejectUnauthorized: false
  });

  const axiosInstance = axios.create({
    baseURL: serverBasePath + "api",
    withCredentials: false,
    httpsAgent: agent,
    headers: {
      Accept: "application/json",
    },
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("AMHZJwtToken");
        router.push("/").then(() => {
          location.reload();
        });
      }
      return Promise.reject(error);
    }
  );
  
  return axiosInstance;
};
