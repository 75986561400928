import api from "../../api";
/* eslint-disable */
export default {
    namespaced: true,
    state: {
        Companies: [],
    },
    getters: {
        getCompanies(state) {
            return state.Companies;
        }
    },
    mutations: {
        SetCompanies(state, data) {
            state.Companies = data;
        },
    },
    actions: {
        GetAllCompanies({ commit }) {
            return new Promise((resolve, rej) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .get(
                        `Companies/all`, {
                            headers: {
                                Authorization: token ? `Bearer ${token}` : '', 
                            }
                        }
                    )
                    .then((res) => {
                        resolve(res);
                        
                        commit("SetCompanies", res.data);
                    })
                    .catch((err) => console.log(err));
            });
        },

        CreateCompany({}, formData) {
            return new Promise((resolve, reject) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .post('Companies', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: token ? `Bearer ${token}` : '', 
                        }
                    })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        resolve(err);
                    });
            });
        },

        UpdateCompany({}, formData) {
            return new Promise((resolve, reject) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .put('Companies', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: token ? `Bearer ${token}` : '', 
                        }
                    })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        resolve(err);
                    });
            });
        },

        DeleteCompany({}, imageId) {
            return new Promise((resolve, reject) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .delete(`Companies/${imageId}`, {
                        headers: {
                            Authorization: token ? `Bearer ${token}` : '', 
                        }
                    })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        resolve(err);
                    });
            });
        }
    }
};
