import api from "../../api";
/* eslint-disable */
export default {
    namespaced: true,
    state: {
        projectTypes: [],
    },
    getters: {
        getProjectTypes(state) {
            return state.projectTypes;
        }
    },
    mutations: {
        SetProjectTypes(state, data) {
            state.projectTypes = [];
            state.projectTypes = data;
        }
    },
    actions: {

        GetAllProjectTypes({ commit }) {
            return new Promise((resolve, rej) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .get(
                        `ProjectTypes/all`, {
                            headers: {
                                Authorization: token ? `Bearer ${token}` : '', 
                            }
                        }
                    )
                    .then((res) => {
                        resolve(res);
                        
                        commit("SetProjectTypes", res.data);
                    })
                    .catch((err) => resolve(err));
            });
        },
        AddNewProjectType({ commit },data) {
            return new Promise((resolve, rej) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .post(
                        `ProjectTypes`,data, {
                            headers: {
                                Authorization: token ? `Bearer ${token}` : '', 
                            }
                        }
                    )
                    .then((res) => {
                        resolve(res);
                       
                    })
                    .catch((err) => resolve(err));
            });
        },
        EditProjectType({  },data) {
            return new Promise((resolve, rej) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .put(
                        `ProjectTypes`,data, {
                            headers: {
                                Authorization: token ? `Bearer ${token}` : '', 
                            }
                        }
                    )
                    .then((res) => {
                        resolve(res);
                       
                    })
                    .catch((err) => resolve(err));
            });
        },
        DeleteProjectType({ commit },data) {
           
            return new Promise((resolve, rej) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .delete(
                        `ProjectTypes/${data}`, {
                            headers: {
                                Authorization: token ? `Bearer ${token}` : '', 
                            }
                        }
                    )
                    .then((res) => {
                        resolve(res);
                       
                    })
                    .catch((err) => resolve(err));
            });
        },
    }
};
