import api from "../../api";
/* eslint-disable */
export default {
    namespaced: true,
    state: {
        EventImages: [],
    },
    getters: {
        getEventImages(state) {
            return state.EventImages;
        }
    },
    mutations: {
        SetEventImages(state, data) {
            state.EventImages = data;
        },
    },
    actions: {
        GetEventImagesByEventId({ commit }, eventId) {
            return new Promise((resolve, reject) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .get(`EventImages/${eventId}`, {
                        headers: {
                            Authorization: token ? `Bearer ${token}` : '', 
                        }
                    })
                    .then((res) => {
                        commit("SetEventImages", res.data);
                        resolve(res);
                    })
                    .catch((err) => {
                        resolve(err);
                    });
            });
        },

        CreateEventImage({}, formData) {
            return new Promise((resolve, reject) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .post('EventImages', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: token ? `Bearer ${token}` : '', 
                        }
                    })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        resolve(err);
                    });
            });
        },

        UpdateEventImage({}, formData) {
            return new Promise((resolve, reject) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .put('EventImages', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: token ? `Bearer ${token}` : '', 
                        }
                    })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        resolve(err);
                    });
            });
        },

        DeleteEventImage({}, imageId) {
            return new Promise((resolve, reject) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .delete(`EventImages/${imageId}`, {
                        headers: {
                            Authorization: token ? `Bearer ${token}` : '', 
                        }
                    })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        resolve(err);
                    });
            });
        }
    }
};
