import api from "../../api";

export default {
  namespaced: true,
  state: {
    token: [],
  },
  getters: {
    getToken(state) {
      return state.token;
    }, 
    
  },
  mutations: {
    SetToken(state, data) {
      state.token = [];
      state.token = data.token;
    },
  },
  actions: {
    Login({ commit }, { userName, password }) {
      return new Promise((resolve, rej) => {
        api()
          .post(`Auth/login`, {
            username: userName,
            password: password,
          })
          .then((res) => {
            if (res.status == 200) {
              commit("SetToken", res.data);
              localStorage.setItem("AMHZJwtToken", res.data.token);

              localStorage.setItem("notificationToken", res.data.token);
              localStorage.setItem("GID", res.data.type);
            }
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    addNotificationToken({ commit }, { token }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `auth/getUserNotificationToken`,
            {
              firebaseNotification: token,
            },
            {
              headers: {
                Authorization: localStorage.getItem("AMHZJwtToken"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              localStorage.setItem("firebaseNotificationToken", token);
             
            }
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    }, 

    getUserNotificationTokenByRealRide({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `auth/getUserNotificationTokenByRealRide`,
           data,
            {
              headers: {
                Authorization: localStorage.getItem("AMHZJwtToken"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
            }
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    }, 

    
    change_password({  },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `auth/change_password`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("AMHZJwtToken"),
            },
          }
        )
        .then((res) => {
          debugger
          resolve(res);
        })
        .catch((err) => {
       
          resolve(err.response);

        });})
    },
  },
};
