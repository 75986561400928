import { createStore } from "vuex";
import auth from "./auth/auth.module.js";
import events from "./events/events.module.js";
import eventImages from "./eventImages/eventImages.module.js";
import ProjectImage from "./ProjectImage/ProjectImage.module.js";
import projects from "./projects/projects.module.js";
import projectTypes from "./projectTypes/projectTypes.module.js";
import Companies from "./company/Companies.module.js";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    events,
    auth,
    eventImages,
    projects,
    projectTypes,
    ProjectImage,
    Companies
  },
});
