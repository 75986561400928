import api from "../../api";
/* eslint-disable */
export default {
    namespaced: true,
    state: {
        ProjectImages: [],
    },
    getters: {
        getProjectImages(state) {
            return state.ProjectImages;
        }
    },
    mutations: {
        SetProjectImages(state, data) {
            state.ProjectImages = data;
        },
    },
    actions: {
        GetProjectImagesByProjectId({ commit }, ProjectId) {
            return new Promise((resolve, reject) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .get(`ProjectImages/${ProjectId}`, {
                        headers: {
                            Authorization: token ? `Bearer ${token}` : '', 
                        }
                    })
                    .then((res) => {
                        commit("SetProjectImages", res.data);
                        resolve(res);
                    })
                    .catch((err) => {
                       
                        resolve(err);
                        
                    });
            });
        },

        CreateProjectImage({}, formData) {
            return new Promise((resolve, reject) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .post('ProjectImages', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: token ? `Bearer ${token}` : '', 
                        }
                    })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        resolve(err);
                    });
            });
        },

        UpdateProjectImage({}, formData) {
            return new Promise((resolve, reject) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .put('ProjectImages', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: token ? `Bearer ${token}` : '', 
                        }
                    })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        resolve(err);
                    });
            });
        },

        DeleteProjectImage({}, imageId) {
            return new Promise((resolve, reject) => {
                const token = localStorage.getItem("AMHZJwtToken"); 
                api()
                    .delete(`ProjectImages/${imageId}`, {
                        headers: {
                            Authorization: token ? `Bearer ${token}` : '', 
                        }
                    })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        resolve(err);
                    });
            });
        }
    }
};
